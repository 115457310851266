import * as React from "react";
import BarLoader from "react-spinners/BarLoader";
import { isIE } from "react-device-detect";

const SuspenseScreen: React.FC = () => {
  return (
    <div className="suspenseScreen">
      <img src="/public/img/logo.svg" />
      <h1>Die Applikation wird vorbereitet...</h1>
      <small>Le site web est en cours de préparation...</small>
      <h3>Prüfe deine Internetverbindung, falls diese Nachricht nicht verschwindet.</h3>
      <small>Vérifiez votre connexion internet si ce message ne disparaît pas.</small>
      {!isIE ?
        <div className="suspenseLoader"><BarLoader loading width="100%" /></div>
        :
        null
      }
    </div>
  );
};

export default SuspenseScreen;
