import * as React from "react";
import { useState } from "react";



const Footer: React.FC = () => {
    const [currentYear, setCurrentYear] = useState<number>(2022);
    React.useEffect(() => {
        var year = new Date().getFullYear();
        setCurrentYear(year);
    }, [window.location.href]);

    return (
        <>
            <div className="printOnly printAddress">
                <address className="container">
                    <strong>Promat AG</strong> | Industriestrasse 3 | 9542 Münchwilen<br />
                    <span style={{color: "#009fe3"}}>T</span> +41 52 320 94 00 | <span style={{color: "#009fe3"}}>E</span> <a href="mailto:office@promat.ch">office@promat.ch</a><br />
                </address>
            </div>
            <footer className="border-top footer text-muted">
                <div className="container">
                    &copy; {currentYear} - Promat AG
                </div>
            </footer>
        </>
    );
};

export default Footer;
