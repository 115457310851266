import React from "react";
import SyncLoader from "react-spinners/SyncLoader";
import ClipLoader from "react-spinners/ClipLoader";
import { useTranslation } from "react-i18next";

interface Props {
  isLoading: boolean;
  isInline?: boolean; // is the spinner inside a button?
  pastDelay?: boolean;
  timedOut?: boolean;
  error?: any;
  retry?: () => void;
}

function load(props: Props) {
  const { t } = useTranslation();

  if (props.error) {
    // When the loader has errored
    return (
      <div className="spinnerWrapper">
        {t("siteCouldNotLoad")}<br/>
      </div>
    );
  } else if (props.timedOut) {
    // When the loader has taken longer than the timeout
    return (
      <div className="spinnerWrapper">
        {t("siteTakingLongerToLoad")}<br/>
      </div>
    );
  } else if (props.isLoading || props.pastDelay) {
    // When the loader has taken longer than the delay
    return (
      <div className={`spinnerWrapper ${props.isInline ? "inlineWrapper" : null}`}>
        {props.isInline ? (
          <ClipLoader loading size={20} color={"#ffffff"} />
        ) : (
          <SyncLoader loading />
        )}
      </div>
    );
  } else {
    // When the loader has just started
    return null;
  }
}

const Loading: React.FC<Props> = (props: Props) => {
  return load(props);
};

export default Loading;
