import * as React from "react";
import * as ReactDOM from "react-dom";
import { Router } from "react-router-dom";
import { createBrowserHistory } from "history";
import App from "./containers/App";
import SuspenseScreen from "./containers/SuspenseScreen";
import { configAxios } from "./utils/axios";
import './i18n';
import { Suspense } from "react";
import {CookiesProvider} from "react-cookie";

configAxios();

ReactDOM.render(
    <CookiesProvider>
        <Suspense fallback={<SuspenseScreen />}>
            <Router history={createBrowserHistory()}>
                <App />
            </Router>
        </Suspense>
    </CookiesProvider>,
  document.getElementById("root")
);
