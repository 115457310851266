import * as React from "react";
import Header from "../components/header/Header";
import Footer from "../components/footer/Footer";
import Routes from "../routes";
import { isIE } from "react-device-detect";
import BrowserNotSupported from "../components/browser-not-supported/BrowserNotSupported";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";

const App: React.FC = () => {
  const { t, i18n } = useTranslation();
  const culture = new URLSearchParams(useLocation().search).get("culture");

  React.useEffect(() => {
    if (culture) localStorage.setItem("languageCode", culture);
    if (localStorage.getItem("languageCode")) {
      i18n.changeLanguage(
        localStorage.getItem("languageCode")
      );
    }
  }, [culture]);

  React.useEffect(() => {
     document.title = t('pageTitle.home');
  }, [i18n.language])

  return (
    <>
      <Header />
      <main>
        <div />
        {isIE ? <BrowserNotSupported /> : <Routes />}
      </main>
      <Footer />
    </>
  );
};

export default App;
