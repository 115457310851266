import * as React from "react";
import { Switch, Route } from "react-router-dom";
import Loading from "./components/loading/Loading";
import * as loadable from 'react-loadable';

const AsyncHomeContainer = loadable ({loader: () => import(/* webpackChunkName: "Home" */ "./containers/home/Home"), loading: Loading});
const AsyncDetailContainer = loadable ({loader: () => import(/* webpackChunkName: "Detail" */ "./containers/detail/Detail"), loading: Loading});
const AsyncNotFoundContainer = loadable({loader: () => import(/* webpackChunkName: "NotFound" */ "./containers/error-pages/NotFound"), loading: Loading});
const AsyncDashboardContainer = loadable({loader : () => import(/* webpackChunkName: "Dashboard" */"./containers/dashboard/Dashboard"), loading: Loading});
const AsyncFireProtectionContainer = loadable({loader : () => import(/* webpackChunkname: "Fireprotection" */"./containers/calculators/Fireprotection"), loading: Loading})
const AsyncFlowrateContainer = loadable({loader : () => import(/* webpackChunkname: "Flowrate" */"./containers/calculators/Flowrate"), loading: Loading});
const AsyncTraverseContainer = loadable({loader : () => import(/* webpackChunkname: "Traverse" */"./containers/calculators/Traverse"), loading: Loading});
const AsyncPressureDropContainer = loadable({loader : () => import(/* webpackChunkname: "Pressuredrop" */"./containers/calculators/Pressuredrop"), loading: Loading});
const AsyncVentilationListContainer = loadable({loader : () => import(/* webpackChunkname: "VentilationList" */"./containers/calculators/VentilationList"), loading: Loading});

const Routes : React.FC = () => {
    return (
        <Switch>
            <Route exact path="/" render={() => <AsyncDashboardContainer /> } />
            <Route exact path="/ventilation" render={() => <AsyncVentilationListContainer /> } />
            <Route exact path="/stahltragwerke" render={() => <AsyncFireProtectionContainer /> } />
            <Route exact path="/ventilation/abhaenger" render={() => <AsyncTraverseContainer /> } />
            <Route exact path="/ventilation/stroemungsgeschwindigkeit" render={() => <AsyncFlowrateContainer /> } />
            <Route exact path="/ventilation/druckverlust" render={() => <AsyncPressureDropContainer /> } />
            <Route exact path="/material/detail" render={() => <AsyncDetailContainer /> } />
            <Route component={AsyncNotFoundContainer} />
        </Switch>
    );
}

export default Routes;