import React, { useState } from "react";
import { useTranslation } from "react-i18next";

export const drawerWidth = 250;

const Header: React.FC = () => {
    const { i18n } = useTranslation();

    const setLanguage = (languageCode: string) => {
        localStorage.setItem("languageCode", languageCode);
        i18n.changeLanguage(languageCode);
    }

    const [backUrl, setBackUrl] = useState<string>("");
    React.useEffect(() => {
        var url = window.location.href;
        if (url.match(/\/ventilation\/.+/)) {
            setBackUrl("/ventilation");
        } else if (url.match(/\/ventilation/)) {
            setBackUrl("/");
        } else if (url.match(/\/stahltragwerke/)) {
            setBackUrl("/");
        }
        else {
            setBackUrl("");
        }
    }, [window.location.href]);

    return (
        <header>
            <div className="container">
                <div className="row">
                    <div className="col-7">
                        <a href="/"><img src="/public/img/logo.svg" alt="Promat Logo" className="logo" /></a>
                        <img src="/public/img/swiss-flag.jpg" alt="Schweizer Kreuz" className="swiss-logo" />
                    </div>
                    <div className="col-5 d-flex justify-content-end align-items-center noPrint">
                        <ul className="langSwitch">
                            <li className={i18n.language === "de" ? "active" : ""}><a onClick={() => setLanguage("de")}>DE</a></li>
                            <li className={i18n.language === "fr" ? "active" : ""}><a onClick={() => setLanguage("fr")}>FR</a></li>
                        </ul>
                        {!backUrl || backUrl == "" ? (
                            <></>
                        ) : (
                            <a href={backUrl} className="back">
                                <img src="/public/img/icons/slider_left.svg" alt="Back to last page" />
                            </a>
                        )}
                    </div>
                </div >
            </div >
        </header>
    );
};

export default Header;
